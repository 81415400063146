<template>
  <div>
    <component-layout :title="'Choose Priority'">
      <v-select
        class="py-5"
        :items="jobPriorityList"
        item-text="title"
        item-value="id"
        v-bind="basic"
        label="Job Priority"
        v-model="priority_id"
        placeholder="Please Select Priority"
        outlined
      ></v-select>

      <div class="d-flex mt-5">
        <v-btn type="button" v-on:click="update" :loading="loading" class="submit-btn mr-4">
          Update
        </v-btn>
        <v-btn type="button" @click="cancel" class="cancel-btn float-right">
          Cancel
        </v-btn>
      </div>
    </component-layout>
  </div>
</template>

<script>
import util from "@/util/util";
import componentLayout from "@/components/component-layout";
export default {
  props: ["id", "selectedJobPriority"],
  components: {
    componentLayout,
  },
  data() {
    return {
      util,
      jobPriorityList: [],
      basic: util.input.basic,
      loading: false,
      priority_id: null,
    };
  },

  mounted() {
    this.getJobPriorityList();
    console.log(this.$props.id);
    this.priority_id = this.$props.selectedJobPriority;
  },

  methods: {
    update() {
      this.loading = true;
      util
        .http({
          url: "/job/change-job-priority",
          method: "PUT",
          data: {
            job_priority_id: this.priority_id,
            job_id: this.$props.id,
          },
        })
        .then((resp) => {
          this.loading = false;
          console.log(resp);
          if (resp.data.status == 1) {
            util.notify(1, "Successfully Updated Priority");
            // this.$router.push({ name: "jobs" });
            this.$emit("update-job-priority", true);
            
          } else {
            util.notify(0, resp.data.errors[0]);
          }
        });
    },
    cancel() {
      this.$emit("cancel", true);
    },
    getJobPriorityList() {
      util.http({ url: "/job-priority/get-all" }).then((res) => {
        this.jobPriorityList = res.data.data;
      });
    },
  },
};
</script>
