<template>
  <div>
    <div class="d-flex mb-6">
      <router-link to="/jobs" class="text-decoration-none">
        <v-btn class="primary-btn">Back</v-btn>
      </router-link>
    </div>
    <component-layout :title="'Job Detail'">
      <template #menu>
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <router-link
              style="color: #000"
              class="text-decoration-none"
              :to="{ path: '/job/' + id + '/edit' }"
            >
              <v-list-item v-ripple="{ center: true }">
                EDIT JOB
              </v-list-item>
            </router-link>
            <v-list-item v-ripple="{ center: true }">
              DELETE JOB
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <v-card class="px-4 py-4" color="basil">
        <v-tabs
          v-model="tab"
          class="tabs"
          background-color="transparent"
          color="basil"
          grow
        >
          <v-tab class="tab">
            <v-icon class="active-icon pr-1" color="black">
              mdi-note
            </v-icon>
            Info
          </v-tab>
          <v-tab class="tab">
            <v-icon class="active-icon pr-1" color="black">
              mdi-note
            </v-icon>
            Notes
          </v-tab>
          <!-- <v-tab class="tab">
            <v-icon class="active-icon pr-1" color="black">
              mdi-note
            </v-icon>
            Files
          </v-tab> -->
          <v-tab class="tab">
            <v-icon class="active-icon pr-1" color="black">
              mdi-note
            </v-icon>
            Charges
          </v-tab>
          <v-tab class="tab">
            <v-icon class="active-icon pr-1" color="black">
              mdi-note
            </v-icon>
            Quotes
          </v-tab>
          <v-tab class="tab">
            <v-icon class="active-icon pr-1" color="black">
              mdi-note
            </v-icon>
            Invoice
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card color="basil" flat>
              <v-lazy>
                <v-card-text><single-job-detail /></v-card-text>
              </v-lazy>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card color="basil" flat>
              <v-lazy>
                <v-card-text><notes /></v-card-text>
              </v-lazy>
            </v-card>
          </v-tab-item>
          <!-- <v-tab-item>
            <v-card color="basil" flat>
              <v-lazy>
                <v-card-text><files /></v-card-text>
              </v-lazy>
            </v-card>
          </v-tab-item> -->
          <v-tab-item>
            <v-card color="basil" flat>
              <v-lazy>
                <v-card-text><charges /></v-card-text>
              </v-lazy>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card color="basil" flat>
              <v-lazy>
                <v-card-text><quotes/></v-card-text>
              </v-lazy>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card color="basil" flat>
              <v-lazy>
                <v-card-text><invoice /></v-card-text>
              </v-lazy>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </component-layout>
  </div>
</template>

<script>
import componentLayout from "@/components/component-layout.vue";
import SingleJobDetail from "@/components/Jobs/JobDetail/single-job-detail.vue";
import notes from "@/components/Jobs/JobDetail/notes.vue";
// import files from "@/components/Jobs/JobDetail/files.vue";
import charges from "@/components/Jobs/JobDetail/charges.vue";
import quotes from "@/components/Jobs/JobDetail/quotes.vue";
import invoice from "@/components/Jobs/JobDetail/invoice.vue";

export default {
  data() {
    return {
      tab: null,
      id: this.$route.params.id,
      data: {},
    };
  },
  components: {
    componentLayout,
    SingleJobDetail,
    notes,
    // files,
    charges,
    quotes,
    invoice,
  },
};
</script>

<style scoped>
.tabs {
  background: #4f4f4f !important;
  color: #fff !important;
}
.tab {
  border: 1px solid rgb(195, 195, 195);
  border-radius: 10px;
  margin: 12px 5px;
  font-size: 10px;
  background: #fff;
  color: #000 !important;
  width: auto !important;
}
.v-tabs--grow > .v-tabs-bar .v-tab {
  flex: 0 0 0;
  max-width: none;
}

.v-tab--active {
  background-color: #1d2b58 !important;
  color: #d1cfcf !important;
}
.v-tab--active .active-icon {
  color: #fff !important;
}
</style>
