<template>
  <div v-if="job">
    <div class="row pb-5 pt-5 pr-3" >
      <div class="col-md-5">
        <v-text-field
          label="Quotation Number"
          v-bind="basic"
          v-model="qnum"
          placeholder="Quotation Number"
          outlined
        ></v-text-field>
      </div>

      <div class="col-md-1" v-if="qnum">
        <v-btn class="primary-bg ml-3" :loading="loading" @click="findQuotation()">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </div>
    </div>
    <div class="quotation" v-if="quotation">
      <div class="row">
        <div class="col-md-6">
          <p>
            <b>Ref No.: {{quotation.ref_no}}</b>
          </p>
          <p>Issue Date: {{quotation.issue_date_formatted}}</p>
          <p>Vallid Till: {{quotation.valid_till_formatted}}</p>
        </div>

        <div class="col-md-6">
          <p>{{quotation.customer.full_name}}</p>
          <p>E: {{quotation.customer.email}}</p>
          <p>phone: {{quotation.customer.number}}</p>
        </div>
      </div>
      <table class="table" style="width: 100%">
        <thead>
          <tr class="table-heading">
            <th scope="col" class="text-start">SN</th>
            <th scope="col" class="text-start">Item Description</th>
            <th scope="col">Quantity</th>
            <th scope="col">Price</th>
            <th scope="col" class="text-end">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in quotation.products" :key="i">
            <td class="text-start ">{{ i+1 }}</td>
            <td class="text-start">{{ item.product.name }}</td>
            <td>{{ item.quantity }}</td>
            <td>{{ item.price }}</td>
            <td class="text-end">{{ total(item.quantity, item.price) }}</td>
          </tr>
          <tr>
            <th colspan="5" style="border-bottom: 1px solid #ccc">
            </th>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th rowspan="4" colspan="3"></th>
            <th class="text-end">Subtotal</th>
            <th class="text-end">{{ quotation.subTotal_formatted }}</th>
          </tr>
          <tr>
            <th class="text-end">Discount</th>
            <th class="text-end">{{ quotation.discount_formatted }}</th>
          </tr>
          <tr>
            <th class="text-end">Tax</th>
            <th class="text-end">{{ quotation.taxTotal_formatted }}</th>
          </tr>
          <tr>
            <th class="text-end">Grand Total</th>
            <th class="text-end">{{ quotation.total_formatted }}</th>
          </tr>
        </tfoot>
      </table>
    </div>

    <div class="row pt-10">
      <v-btn class="success-btn" block :loading="loading" v-if="qnum" @click="assignQuotation()">Assign</v-btn>
    </div>
  </div>
</template>

<script>
import util from "@/util/util";

export default {
  data() {
    return {
      job: null,
      qnum: null,
      quotation: null,
      util,
      loading: false,
      basic: util.input.basic
    };
  },
  methods: {
    calculateTotal() {
      this.form.total = this.form.quantity * this.form.price;
    },
    getSingleJobDetail() {
      util
        .http({ url: "/job/" + this.$route.params.id, method: "get" })
        .then(res => {
          console.log(res);
          this.job = res.data.data;
          if (this.job.quotation_id) {
            this.quotation = this.job.quotation;
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    findQuotation() {
      util
        .http({
          url: "quotation/find",
          method: "get",
          params: {
            qnum: this.qnum
          }
        })
        .then(res => {
          if (res.data.status == 1) {
          this.quotation = res.data.data;
          } else {
            util.notify(0,res.data.errors[0]);
          }
        }).catch(err => {
          util.notify(0, err.response.data.message);
        });
    },
    total(q, p) {
      return util.total(q, p);
    },
    assignQuotation() {
      this.loading = true;
      util
        .http({
          url: "job/change-quotation",
          method: "put",
          params: {
            job_id: this.job.id,
            quotation_id: this.quotation.id
          }
        })
        .then(res => {
          if (res.data.status == 1) {
            util.notify(1,res.data.message);
          } else {
            util.notify(0,res.data.errors[0]);
          }
        }).catch(err => {
          util.notify(0, err.response.data.message);
        }).finally(() => {
          this.qnum = null;
          this.loading = false;
        });
    }
  },
  mounted() {
    this.getSingleJobDetail();
  }
};
</script>

<style scoped>
.table-heading {
  background-color: #1d2b58;
}
.table-heading th {
  color: #fff;
}
.quotation {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
  background: #f1f1f1;
}
.table th, .table td{
  padding: 8px 12px !important;
}
</style>
