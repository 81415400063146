<template>
  <div>
    <component-layout :title="'Choose Type'">
      <v-select
        class="py-5"
        :items="jobTypeList"
        item-text="title"
        item-value="id"
        v-bind="basic"
        label="Job Type"
        v-model="job_type_id"
        placeholder="Please Select Type"
        outlined
      ></v-select>

      <div class="d-flex mt-5">
        <v-btn type="button" v-on:click="update" :loading="loading" class="submit-btn mr-4">
          Update
        </v-btn>
        <v-btn type="button"  @click="cancel" class="cancel-btn float-right">
          Cancel
        </v-btn>
      </div>
    </component-layout>
  </div>
</template>

<script>
import util from "@/util/util";
import componentLayout from "@/components/component-layout";
export default {
  props: ["id", "selectedJobType"],

  components: {
    componentLayout,
  },
  data() {
    return {
      util,
      jobTypeList: [],
      basic: util.input.basic,
      job_type_id: null,
      loading: false,
    };
  },

  mounted() {
    this.getJobTypeList();
    this.job_type_id = this.$props.selectedJobType;
    console.log(this.$props.id);
    console.log(this.$props.selectedJobType);
  },

  methods: {
    update() {
      this.loading = true;
      util
        .http({
          url: "/job/change-job-type",
          method: "PUT",
          data: {
            job_type_id: this.job_type_id,
            job_id: this.$props.id,
          },
        })
        .then((resp) => {
          this.loading = false;
          console.log(resp);
          if (resp.data.status == 1) {
            util.notify(1, "Successfully Updated Type");
            // this.$router.push({ name: "jobs" });
            this.$emit("update-job-type", true);
          } else {
            util.notify(0, resp.data.errors[0]);
          }
        });
    },
    getJobTypeList() {
      util.http({ url: "/job-type/get-all" }).then((res) => {
        this.jobTypeList = res.data.data;
      });
    },
    cancel() {
      this.$emit("cancel", true);
    },
  },
  watch: {
    "$props.id": function() {
      this.getJobTypeData();
    },
  },
};
</script>
