<template>
  <div>
    <div class="row pb-5 pt-5 pr-3" v-if="products.length > 0">
      <div class="col-md-5">
        <v-select
          :items="products"
          item-text="name"
          item-value="id"
          v-bind="basic"
          label="Product"
          v-model="form.product_id"
          :rules="[rules.required]"
          placeholder="Select Option"
          outlined
        ></v-select>
      </div>
      <div class="col-md-2">
        <v-text-field
          label="Quantity"
          v-bind="basic"
          v-model="form.quantity"
          placeholder="Quantity"
          :rules="[rules.required]"
          outlined
        ></v-text-field>
      </div>
      <div class="col-md-2">
        <v-text-field
          label="Price"
          v-bind="basic"
          v-model="form.price"
          :rules="[rules.required]"
          placeholder="Price"
          outlined
        ></v-text-field>
      </div>
      <div class="col-md-2">
        <v-text-field
          label="Total"
          v-bind="basic"
          v-model="form.total"
          placeholder="Total"
          disabled
          filled
          outlined
        ></v-text-field>
      </div>
      <div class="col-md-1">
        <v-btn class="primary-bg ml-3" v-if="id" @click="updateCharge()"
          ><v-icon>mdi-check</v-icon></v-btn
        >
        <v-btn class="primary-bg ml-3" @click="addCharge()" v-else
          ><v-icon>mdi-plus</v-icon></v-btn
        >
      </div>
    </div>
    <table class="table" style="width: 100%">
      <thead>
        <tr class="table-heading">
          <th scope="col" class="text-start px-3 py-2 ">SN</th>
          <th scope="col" class="text-start px-3 py-2">Item Description</th>
          <th scope="col" class="text-start px-3 py-2">Quantity</th>
          <th scope="col" class="text-start px-3 py-2">Price</th>
          <th scope="col" class="text-start px-3 py-2">Total</th>
          <th scope="col" class="text-start px-3 py-2">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, i) in charges" :key="i">
          <td class="text-start px-3 py-2">{{ item.id }}</td>
          <td class="text-start px-3 py-2">{{ item.product.name }}</td>

          <td class="text-start px-3 py-2">{{ item.quantity }}</td>
          <td class="text-start px-3 py-2">{{ item.price_formatted }}</td>
          <td class="text-start px-3 py-2">
            {{ item.total_formatted }}
          </td>
          <td>
            <button class="btn ml-4" @click="edit(item)">
              <i class="fa fa-pencil edit-btn"></i>
            </button>

            <button class="btn ml-4" @click="deletePopup(item)">
              <i class="fa fa-trash delete-btn"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <v-dialog v-model="showDeleteModal" v-if="id" max-width="450px">
      <deleteDialog
        resource="job-charge"
        :id="id"
        @reload-resource="reloadResource"
        @cancel="cancel"
      />
    </v-dialog>
    <div class="row justify-end pt-5 pr-3">
      <h3>Grand Total: ${{ grandtotal }}</h3>
    </div>
  </div>
</template>

<script>
import util from "@/util/util";
import deleteDialog from "@/components/shared/delete-modal";

export default {
  components: { deleteDialog },
  data() {
    return {
      util,
      form: {
        product_id: null,
        quantity: 0,
        price: 0,
        total: 0.0,
      },
      loading: false,
      grandtotal: 0,
      id: null,
      rules: util.validation,
      basic: util.input.basic,
      products: [],
      charges: [],
      showDeleteModal: false,
    };
  },
  methods: {
    getProducts() {
      util
        .http({
          url: "product/get-all",
        })
        .then((resp) => {
          this.products = resp.data.data;
        })
        .catch((err) => {
          console.log(err);
          util.notify(0, "Error in Process");
        });
    },
    getCharges() {
      util
        .http({
          url: "job-charge/get-all/" + this.$route.params.id,
          data: {
            id: this.$route.params.id,
          },
        })
        .then((resp) => {
          if (resp.data.status == 1) {
            this.charges = resp.data.data;
            this.calculalateGrandTotal();
          } else {
            util.notify(0, resp.data.errors[0]);
          }
        })
        .catch((resp) => {
          util.notify(0, resp.data.errors[0]);
        });
    },
    total(q, p) {
      let total = q * p;
      return total.toFixed(2);
    },
    calculateTotal() {
      this.form.total = this.total(this.form.quantity, this.form.price);
    },
    calculalateGrandTotal() {
      let total = 0;
      this.charges.forEach((charge) => {
        total += parseFloat(this.total(charge.quantity, charge.price));
      });
      this.grandtotal = total.toFixed(2);
    },
    addCharge() {
      // if (this.$refs.form.validate()) {
      this.loading = true;
      util
        .http({
          url: "job-charge",
          method: "post",
          data: {
            ...this.form,
            job_id: this.$route.params.id,
          },
        })
        .then((resp) => {
          if (resp.data.status) {
            util.notify(1, resp.data.message);
            this.getCharges();
            this.form = {
              product_id: null,
              quantity: 0,
              price: 0,
              total: 0,
            };
          } else {
            util.notify(0, resp.data.errors[0]);
          }
        })
        .catch((resp) => {
          console.log("error" + resp);
        })
        .finally(() => {
          this.loading = false;
        });
      // }
    },

    edit(item) {
      this.id = item.id;
      this.form.product_id = item.product_id;
      this.form.quantity = item.quantity;
      this.form.price = item.price;
      this.calculateTotal();
      console.log(this.form);
    },
    updateCharge() {
      this.loading = true;
      util
        .http({
          url: "/job-charge/" + this.id,
          method: "PUT",
          data: { ...this.form },
        })
        .then((resp) => {
          if (resp.data.status) {
            util.notify(1, resp.data.message);
            this.reloadResource();
          } else {
            util.notify(0, resp.data.errors[0]);
          }
        })
        .catch((resp) => {
          console.log("error" + resp);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // delete
    deletePopup(charge) {
      this.showDeleteModal = true;
      this.id = charge.id;
    },

    reloadResource() {
      this.cancel();
      this.getCharges();
    },
    cancel() {
      this.showDeleteModal = false;
      this.id = null;
      this.form.product_id = null;
      this.form.quantity = 0;
      this.form.price = 0;
      this.form.total = 0;
    },
  },
  mounted() {
    this.getProducts();
    this.getCharges();
  },
  watch: {
    "form.product_id": function() {
      if (this.products.length > 0 && this.form.product_id) {
        let product = this.products.find((p) => p.id == this.form.product_id);
        if (this.form.price == 0) {
          this.form.price = product.price;
        }
        if (this.form.quantity == 0) {
          this.form.quantity = 1;
        }
        this.calculateTotal();
      }
    },
    "form.quantity": function() {
      this.calculateTotal();
    },
    "form.price": function() {
      this.calculateTotal();
    },
  },
};
</script>

<style scoped>
.table-heading {
  background-color: #1d2b58;
}
.table-heading th {
  color: #fff;
}
</style>
