<template>
  <div>
    <div class="row pb-5">
      <div class="notes">
        <v-form ref="form" lazy-validation>
          <div class="d-flex">
            <v-text-field
              label="Notes"
              v-bind="basic"
              placeholder="Notes Here"
              v-model="note"
              outlined
              :rules="[rules.required]"
            ></v-text-field>
            <v-btn class="primary-bg ml-3" v-if="id" @click="updateNote()">
              <v-icon>mdi-check</v-icon>
            </v-btn>
            <v-btn class="primary-bg ml-3" v-else @click="addNote()">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
        </v-form>
        <br />
        <div v-for="(n, i) in notes" :key="i" class="single-note  py-4">
          <div class="row">
            <div class="col-md-10">
              {{ n.note }}
            </div>
            <div class="col-md-2">
              <div class="d-flex float-right">
                <div class="edit-btn mr-3" @click="edit(n)">
                  <i class="fa fa-pencil"></i>
                </div>
                <div class="remove-btn" @click="deletePopup(n)">
                  <i class="fa fa-trash"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="author-date">
            <span class="mr-5">
              <i class="fa fa-user"></i> {{ n.created_by.full_name }}
            </span>
            <span>
              <i class="fa fa-calendar"></i> {{ n.created_at_formatted }}
            </span>
          </div>
        </div>
      </div>
      <v-dialog v-model="showDeleteModal" v-if="id" max-width="450px">
        <deleteDialog
          resource="job-note"
          :id="id"
          @cancel="cancel"
          @reload-resource="reloadResource"
        />
      </v-dialog>
    </div>
  </div>
</template>
<script>
import util from "@/util/util";
import deleteDialog from "@/components/shared/delete-modal";
export default {
  components: { deleteDialog },
  data() {
    return {
      util,
      rules: util.validation,
      basic: util.input.basic,
      note: "",
      notes: [],
      id: null,
      loading: false,
      showDeleteModal: false,
    };
  },
  mounted() {
    this.getNotes();
  },
  methods: {
    addNote() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        util
          .http({
            url: "job-note",
            method: "post",
            data: {
              note: this.note,
              job_id: this.$route.params.id,
            },
          })
          .then((resp) => {
            if (resp.data.status) {
              util.notify(1, resp.data.message);
              this.note = "";
              this.getNotes();
            } else {
              util.notify(0, resp.data.errors[0]);
            }
          })
          .catch((resp) => {
            console.log("error" + resp);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    edit(note) {
      this.id = note.id;
      this.note = note.note;
    },
    updateNote() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        util
          .http({
            url: "job-note/" + this.id,
            method: "patch",
            data: {
              note: this.note,
            },
          })
          .then((resp) => {
            if (resp.data.status) {
              util.notify(1, resp.data.message);
              this.note = "";
              this.id = null;
              this.getNotes();
            } else {
              util.notify(0, resp.data.errors[0]);
            }
          })
          .catch((resp) => {
            console.log("error" + resp);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    deletePopup(note) {
      this.showDeleteModal = true;
      this.id = note.id;
    },
    cancel() {
      this.showDeleteModal = false;
      this.id = "";
    },
    reloadResource() {
      this.cancel();
      this.getNotes();
    },
    getNotes() {
      util
        .http({
          url: "job-note/get-all/" + this.$route.params.id,
        })
        .then((resp) => {
          if (resp.data.status == 1) {
            this.notes = resp.data.data;
          } else {
            util.notify(0, resp.data.errors[0]);
          }
        })
        .catch((resp) => {
          util.notify(0, resp.data.errors[0]);
        });
    },
  },
};
</script>
<style scoped>
.notes {
  padding: 20px;
  background-color: #fff;
  color: #555555;
  border-bottom: 3px solid #e5e5e5;
  width: 100%;
}
.edit-btn {
  background-color: #ff9a02;
  color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.remove-btn {
  background-color: #ff0000;
  color: #fff;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.author-date {
  font-size: 10px;
  color: #bbb;
  font-weight: 700;
}
.single-note {
  border-bottom: 1px dotted #ccc;
}
</style>
