<template>
  <div>
    <component-layout :title="'Assign User'">
      <v-col cols="12">
        <v-autocomplete
          v-model="selected_users"
          :items="users"
          item-text="full_name"
          item-value="id"
          outlined
          dense
          chips
          small-chips
          label="Outlined"
          multiple
        ></v-autocomplete>
        <v-btn class="submit-btn mr-3" :loading="loading" @click="assign">
          Assign
        </v-btn>
        <v-btn class="cancel-btn float-right" @click="cancel">
          Cancel
        </v-btn>
      </v-col>
    </component-layout>
  </div>
</template>

<script>
import util from "@/util/util";
import componentLayout from "../../component-layout.vue";
export default {
  props: ["id", "assign_users"],

  components: { componentLayout },
  data: () => ({
    users: [],
    value: null,
    user_id: null,
    selected_users: [],
    loading: false,
  }),
  mounted() {
    this.getAllUsers();
    console.log(this.$props.selectedUsers);
    // this.user_id = this.$props.selectedUsers;
    this.getSelectedUsers();
    console.log(this.selected_users);
  },
  methods: {
    getAllUsers() {
      util
        .http({
          url: "/user/get-all",
        })
        .then((res) => {
          this.users = res.data.data;
        });
    },
    getSelectedUsers() {
      this.assign_users.forEach((user) => {
        this.selected_users.push(user.user.id);
      });
    },
    assign() {
      this.loading = true;
      util
        .http({
          url: "/job/assign-users",
          method: "POST",
          data: {
            job_id: this.$props.id,
            assignees: this.selected_users,
          },
        })
        .then((resp) => {
          this.loading = false;
          console.log(resp);
          if (resp.data.status == 1) {
            util.notify(1, "Successfully Assigned Users");
            // this.$router.push({ name: "jobs" });
            this.$emit("update-assign-user", true);
          } else {
            util.notify(0, resp.data.errors[0]);
          }
        });
    },
    cancel() {
      this.$emit("cancel", true);
    },
  },
};
</script>
