<template>
  <div v-if="data">
    <div class="row pb-5">
      <div class="col-md-6">
        <div class="d-flex">
          <div class="col-md-4 px-0 py-0">
            <div class="titles">Job No:</div>
          </div>
          <div class="col-md-8 px-0 py-0">
            <div class="details">{{ data.job_no }}</div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="d-flex">
          <div class="col-md-4 px-0 py-0">
            <div class="titles">Job Title:</div>
          </div>
          <div class="col-md-8 px-0 py-0">
            <div class="details">{{ data.job_title }}</div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="d-flex">
          <div class="col-md-4 px-0 py-0">
            <div class="titles">Start Date:</div>
          </div>
          <div class="col-md-8 px-0 py-0">
            <div class="details start-date">{{ data.start_date }}</div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="d-flex">
          <div class="col-md-4 px-0 py-0">
            <div class="titles">Due Date:</div>
          </div>
          <div class="col-md-8 px-0 py-0">
            <div class="details end-date">{{ data.due_date }}</div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="d-flex">
          <div class="col-md-4 px-0 py-0">
            <div class="titles">Progress:</div>
          </div>
          <div class="col-md-8 px-0 py-0">
            <div class="details">{{ data.progress }}%</div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="d-flex">
          <div class="col-md-4 px-0 py-0">
            <div class="titles">Code:</div>
          </div>
          <div class="col-md-8 px-0 py-0">
            <div class="details">{{ data.code }}</div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="d-flex">
          <div class="col-md-4 px-0 py-0">
            <div class="titles">Customer:</div>
          </div>
          <div class="col-md-8 px-0 py-0">
            <div class="details">
              {{ data.customer ? data.customer.full_name : "" }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="d-flex">
          <div class="col-md-4 px-0 py-0">
            <div class="titles">Job Site:</div>
          </div>
          <div class="col-md-8 px-0 py-0">
            <div class="details">{{ data.job_site }}</div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="d-flex align-center">
          <div class="col-md-4 px-0 py-0">
            <div class="titles">Job Type:</div>
          </div>
          <div class="col-md-8 px-0 py-0">
            <div class="row">
              <div
              v-if="data.job_type_id"
                class="details status-preview px-15 mr-3"
                :style="
                  'background: ' +
                    (data.job_type != null
                      ? data.job_type.background_color
                      : '') +
                    ';color: ' +
                    (data.job_type != null ? data.job_type.text_color : '')
                "
              >
                {{ data.job_type ? data.job_type.title : "" }}
              </div>
              <v-dialog v-model="dialogType" width="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" color="#ff9a02">mdi-pencil</v-icon>
                </template>

                <v-card class="py-3 px-3">
                  <job-type
                    v-if="data"
                    :id="data.id"
                    :selectedJobType="data.job_type_id"
                    @update-job-type="updateJobType"
                    @cancel="cancel"
                  />

                  <v-divider></v-divider>
                </v-card>
              </v-dialog>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="d-flex">
          <div class="col-md-4 px-0 py-0">
            <div class="titles">Order Number:</div>
          </div>
          <div class="col-md-8 px-0 py-0">
            <div class="details">{{ data.order_number }}</div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="d-flex align-center">
          <div class="col-md-4 px-0 py-0">
            <div class="titles">Priority:</div>
          </div>
          <div class="col-md-8 px-0 py-0">
            <div class="row">
              <div v-if="data.priority_id"
                class="details status-preview px-15 mr-3"
                :style="
                  'background: ' +
                    (data.priority != null
                      ? data.priority.background_color
                      : '') +
                    ';color: ' +
                    (data.priority != null ? data.priority.text_color : '')
                "
              >
                {{ data.priority ? data.priority.title : "" }}
              </div>
              <v-dialog v-model="dialogPriority" width="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" color="#ff9a02">mdi-pencil</v-icon>
                </template>

                <v-card class="py-3 px-3">
                  <job-priority
                    v-if="data"
                    :id="data.id"
                    :selectedJobPriority="data.priority_id"
                    @update-job-priority="updateJobPriority"
                    @cancel="cancel"
                  />

                  <v-divider></v-divider>
                </v-card>
              </v-dialog>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="d-flex">
          <div class="col-md-4 px-0 py-0">
            <div class="titles">Is Internal:</div>
          </div>
          <div class="col-md-8 px-0 py-0">
            <div class="details">{{ data.is_internal }}</div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="d-flex align-center">
          <div class="col-md-4 px-0 py-0">
            <div class="titles">Status:</div>
          </div>
          <div class="col-md-8 px-0 py-0">
            <div class="row">
              <div
              v-if="data.job_status_id"
                class="details status-preview px-15 mr-3"
                :style="
                  'background: ' +
                    (data.job_status != null
                      ? data.job_status.background_color
                      : '') +
                    ';color: ' +
                    (data.job_status != null ? data.job_status.text_color : '')
                "
              >
                {{ data.job_status ? data.job_status.title : "" }}
              </div>
              <v-dialog v-model="dialogStatus" width="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" color="#ff9a02">mdi-pencil</v-icon>
                </template>

                <v-card class="py-3 px-3">
                  <job-status
                    v-if="data"
                    :id="data.id"
                    :selectedJobStatus="data.job_status_id"
                    @update-job-status="updateJobStatus"
                    @cancel="cancel"
                  />

                  <v-divider></v-divider>
                </v-card>
              </v-dialog>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="d-flex">
          <div class="col-md-4 px-0 py-0">
            <div class="titles">Created By:</div>
          </div>
          <div class="col-md-8 px-0 py-0">
            <div class="details">{{ data.created_by.email }}</div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="d-flex">
          <div class="col-md-4 px-0 py-0">
            <div class="titles">Assigned User:</div>
          </div>
          <div class="col-md-8 px-0 py-0">
            <div class="details d-flex">
              <div
                class="d-flex assigned-user-row"
                v-for="(item, i) of data.assignees"
                :key="i"
              >
                <div class="assigned-user-circle mr-1 text-white">
                  {{ testimonialUser(item.user.first_name)
                  }}{{ testimonialUser(item.user.last_name) }}
                </div>
              </div>
              <v-dialog v-model="dialogAssignUsers" width="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" color="#3CA62B">mdi-plus</v-icon>
                </template>

                <v-card class="py-3 px-3">
                  <assign-users
                    v-if="data"
                    :id="data.id"
                    :assign_users="data.assignees"
                    @update-assign-user="updateAssignUsers"
                    @cancel="cancel"
                  />

                  <v-divider></v-divider>
                </v-card>
              </v-dialog>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="d-flex">
          <div class="col-md-4 px-0 py-0">
            <div class="titles">Job Description:</div>
          </div>
          <div class="col-md-8 px-0 py-0">
            <div class="details">{{ data.job_description }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-10">
      <table class="table" style="width: 100%">
        <thead>
          <tr class="table-heading">
            <th scope="col" class="text-start px-3 py-2 ">Status</th>
            <th scope="col" class="text-start px-3 py-2">Created Date</th>
            <th scope="col" class="text-start px-3 py-2">Created By</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in data.status_histories" :key="i">
            <td class="text-start px-3 py-2">
              {{ item.job_status.title }}
            </td>
            <td class="text-start px-3 py-2">{{ item.created_at_formatted }}</td>
            <td class="text-start px-3 py-2">
              <span>{{ data.created_by.email }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import util from "@/util/util";
import jobType from "../JobDetail/editDialogue/job-type.vue";
import JobPriority from "../JobDetail/editDialogue/job-priority.vue";
import JobStatus from "../JobDetail/editDialogue/job-status.vue";
import AssignUsers from "./assignUsers.vue";
export default {
  components: { jobType, JobPriority, JobStatus, AssignUsers },
  data() {
    return {
      id: this.$route.params.id,
      data: null,
      dialogStatus: false,
      dialogPriority: false,
      dialogType: false,
      dialogAssignUsers: false,
    };
  },
  mounted() {
    console.log(this.id);
    this.getSingleJobDetail();
  },

  methods: {
    getSingleJobDetail() {
      util
        .http({ url: "/job/" + this.$route.params.id, method: "get" })
        .then((res) => {
          console.log(res);
          this.data = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // assigned users
    chunk(len) {
      let arr = this.$store.state.data.testimonials;
      var chunks = [],
        i = 0,
        n = arr.length;
      while (i < n) {
        chunks.push(arr.slice(i, (i += len)));
      }
      return chunks;
    },
    testimonialUser(val) {
      return val.charAt(0);
    },

    updateJobType() {
      this.dialogType = false;
      this.getSingleJobDetail();
    },
    updateJobPriority() {
      this.dialogPriority = false;
      this.getSingleJobDetail();
    },
    updateJobStatus() {
      this.dialogStatus = false;
      this.getSingleJobDetail();
    },
    updateAssignUsers() {
      this.dialogAssignUsers = false;
      this.getSingleJobDetail();
    },
    cancel() {
      this.dialogPriority = false;
      this.dialogStatus = false;
      this.dialogType = false;
      this.dialogAssignUsers = false;
    },
  },
};
</script>

<style scoped>
.titles {
  font-size: 16px;
  color: #868686;
  font-weight: 700;
}
.add-more-btn {
  font-size: 20px;
  background: #1d2b58;
}
.details {
  font-size: 16px;
  font-weight: 900;
  color: #000;
}
.start-date {
  color: #0bd707 !important;
}
.end-date {
  color: #ff0000 !important;
}
.status-preview {
  background: #010b64;
  /* color: #fff !important; */
  padding: 5px;
  display: flex;
  justify-content: center;
  border-radius: 10px;
}
.assigned-user-circle {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #1d2b58;
  display: flex;
  justify-content: center;
  align-items: center;
}
.table-heading {
  background-color: #1d2b58;
}
.table-heading th {
  color: #fff;
}
</style>
