<template>
  <div>
    <div class="row pt-5 pr-3">
      <div class="col-md-4">
        <v-btn class="submit-btn w-100" :loading="loading" @click="generateInvoice()">Generate Invoice</v-btn>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12" v-for="(item, i) in data.invoices" :key="i">
        <v-btn
          outlined
          color="indigo"
        >{{ item.ref_no }} 
        </v-btn> <i class="fa fa-pencil fa-2x edit-btn ms-2" title="Edit" style="cursor: pointer" @click="editInvoice(item.id)"></i>
      </div>
    </div>
  </div>
</template>

<script>
import util from "@/util/util";

export default {
  components: {},
  data() {
    return {
      util,
      menu1: false,
      menu2: false,
      loading: false,
      data: null,
    };
  },
  methods: {
    getInvoice() {
      util
        .http({ url: "/job/" + this.$route.params.id, method: "get" })
        .then(res => {
          console.log(res.data.data);
          this.data = res.data.data;
        })
        .catch(err => {
          console.log(err);
        });
    },
    generateInvoice() {
      this.loading = true;
      util
        .http({
          url: "/invoice/create-by-job/" + this.$route.params.id,
          method: "post"
        })
        .then(res => {
          console.log(res.data.data);
          this.$router.push({ name: 'invoice-edit', params: { id: res.data.data.id} });
        })
        .catch(err => {
          console.log(err);
        });
    },
    editInvoice (id){
      this.$router.push({ name: 'invoice-edit', params: { id: id} });

    }
  },
  mounted() {
    this.getInvoice();
  },
};
</script>

<style scoped>
.table {
  border: 2px solid #000000;
}
.table-heading {
  background-color: #1d2b58;
}
.table-heading th {
  color: #fff;
}
.tax-and-totals .titles,
.tax-and-totals .details {
  font-size: 18px;
}
</style>
